import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import FeatureTwo from '../../components/features/FeatureTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Services = () => {
  return (
    <Layout>
      <Navbar classOption='navbar-light' />
      <PageHeader title='We are Infrastructure Experts' desc='We give you excellent value for money with our tried and tested approach to design and delivery.' />
      <FeatureTwo />
      <FooterOne
        style={{
          background: "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Services;

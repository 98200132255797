import React from 'react';
import BlogDetails from '../../components/blog/BlogDetails';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const SingleBlog = () => {
  return (
    <Layout>
      <PageMeta title='Blog Details - Software &amp; IT Solutions HTML Template' />
      <Navbar />
      <PageHeader title='Why customer retention is the ultimate growth strategy' />
      <BlogDetails />
      <FooterOne
        style={{
          background: "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default SingleBlog;

import React from 'react';
import SectionTitle from '../common/SectionTitle';

const Promo = ({ bgWhite }) => {
  return (
    <>
      <section className={`promo-section ptb-120 ${bgWhite ? 'bg-white' : 'bg-light'} `}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle title='The Zoja System Approach' centerAlign />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div className='bg-dark-light p-5 text-center d-flex flex-column h-100 rounded-custom' data-aos='fade-up' data-aos-delay='100'>
                <div className='promo-card-info mb-4'>
                  <h3 className='display-6 fw-bold mb-4 text-white'>
                    <i className='far fa-fingerprint text-warning me-2'></i> Plan
                  </h3>
                  <p className='text-white'>We partner with your team to ensure you have everything you need to get a scalable and secure infrastructure</p>
                </div>
                {/* <div className='mt-auto'>
                  <img src='assets/img/clients/client-logo-4.svg' width='120' alt='clients' className='img-fluid me-auto customer-logo' />
                </div> */}
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div className='bg-dark-light p-5 text-center d-flex flex-column h-100 rounded-custom' data-aos='fade-up' data-aos-delay='150'>
                <div className='promo-card-info mb-4'>
                  <h3 className='display-6 fw-bold mb-4 text-white'>
                    <i className='far fa-paper-plane text-warning me-2'></i> Automate
                  </h3>
                  <p className='text-white'>We tailor build pipelines to support the deployment of a predictable and immutable infrastructure</p>
                </div>
                {/* <div className='mt-auto'>
                  <img src='assets/img/clients/client-logo-2.svg' width='120' alt='clients' className='img-fluid me-auto customer-logo' />
                </div> */}
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0'>
              <div className='bg-dark-light p-5 text-center d-flex flex-column h-100 rounded-custom' data-aos='fade-up' data-aos-delay='200'>
                <div className='promo-card-info mb-4'>
                  <h3 className='display-6 fw-bold mb-4 text-white'>
                    <i className='far fa-chart-pie-alt text-warning me-2'></i>
                    Manage
                  </h3>
                  <p className='text-white'>Knowledgeable, experienced and certified Engineers provide support to ensure your applications are secure and running to achieve your SLAs and SLOs.</p>
                </div>
                {/* <div className='mt-auto'>
                  <img src='assets/img/clients/client-logo-3.svg' width='120' alt='clients' className='img-fluid me-auto customer-logo' />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;

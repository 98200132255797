import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import NotFoundScreen from '../components/others/NotFoundScreen';
import About from '../pages/About';
import Blogs from '../pages/blog/Blogs';
import SingleBlog from '../pages/blog/SingleBlog';
import ComingSoon from '../pages/ComingSoon';
import Contact from '../pages/Contact';
import RequestDemo from '../pages/RequestDemo';
import Services from '../pages/services/Services';
import HomeDesktopApp from '../themes/index3/HomeDesktopApp';

class Routes extends Component {
  render() {
    return (
      <>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path='/' component={HomeDesktopApp} />
            <Route exact path='/about-us' component={About} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/contact-us' component={Contact} />
            <Route exact path='/blogs' component={Blogs} />
            <Route exact path='/blog-single' component={SingleBlog} />
            <Route exact path='/request-for-demo' component={RequestDemo} />
            <Route exact path='/coming-soon' component={ComingSoon} />
            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
      </>
    );
  }
}
export default Routes;

import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const IntegrationTwo = () => {
  return (
    <>
      <section className='integration-section ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <SectionTitle
              title='We partner with top vendors'
              description='  Dynamically pursue convergence rather than 24/7 process
                  improvements develop end-to-end customer service action items.'
              leftAlign
            />
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='integration-wrapper position-relative w-100'>
                <ul className='integration-list list-unstyled mb-0'>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <div className='single-integration'>
                      <img src='assets/img/integations/vmware.png' alt='integration' className='img-fluid' />
                      <h6 className='mb-0 mt-4'>VMware</h6>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <div className='single-integration'>
                      <img src='assets/img/integations/microsoft.png' alt='integration' className='img-fluid' />
                      <h6 className='mb-0 mt-4'>Microsoft</h6>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <div className='single-integration'>
                      <img src='assets/img/integations/dell.png' alt='integration' className='img-fluid' />
                      <h6 className='mb-0 mt-4'>Dell</h6>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <div className='single-integration'>
                      <img src='assets/img/integations/google.png' alt='integration' className='img-fluid' />
                      <h6 className='mb-0 mt-4'>Google</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationTwo;

import React from 'react';
import AboutPageHero from '../components/about/AboutPageHero';
import OurStory from '../components/about/OurStory';
import PageMeta from '../components/common/PageMeta';
import Team from '../components/team/Team';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const About = () => {
  return (
    <Layout>
      <PageMeta title='About us - Software &amp; IT Solutions HTML Template' />
      <Navbar classOption='navbar-light' />
      <AboutPageHero />
      <OurStory />
      <Team />
      <FooterOne
        style={{
          background: "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default About;

import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section className={`feature-section ptb-120 ${cardDark ? 'bg-dark' : 'bg-light'}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className=''>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${cardDark ? 'bg-custom-light promo-border-hover border border-2 border-light text-white' : 'bg-white'}`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-cloud icon-sm text-primary'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Virtualization and Cloud</h3>
                    <div className='mb-0'>
                      The adoption of virtualization and cloud technologies by I.T organizations has ushered in changes in technology architecture since the introduction of client-server computing.
                      <br />
                      <br />
                      Zoja Systems partners with companies to implement comprehensive enterprise-wide virtualization and cloud roadmaps.
                      <br />
                      <br />
                      Our technical experts can:
                      <ul>
                        <li>Design virtualization programs for server and storage systems so that you can consolidate your infrastructure</li>
                        <li>
                          Develop Service-enable key processes by using cloud computing models to integrate people, processes, and technologies while breaking down technical silos that hinder agility
                        </li>
                        <li>Implement governance practices and controls that allow you to protect the integrity of data and applications in this new computing environment</li>
                        <li>Establish a framework that supports continual improvement and lays the foundation for innovative developments in technology or business process design</li>
                      </ul>
                      <br />
                      Contact us today to meet with one of our virtualization and cloud subject matter experts to learn more.
                    </div>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 mt-4 ${cardDark ? 'bg-custom-light promo-border-hover border border-2 border-light text-white' : 'bg-white'}`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-network-wired icon-sm text-primary'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Infrastructure Solutions</h3>
                    <p className='mb-0'>
                      Infrastructure solutions are at the core of today’s technology deployments. It is essential that these systems provide the reliability and performance to meet the complex demands
                      of today’s organizations.
                      <br />
                      <br />
                      Zoja System's team of infrastructure solution specialists have real-life infrastructure solution delivery experience with highly available global deployments and industry
                      recognized certifications to ensure successful project delivery.
                      <br />
                      <br />
                      The Infrastructure Solutions team works to ensure a solid foundation designed to address the business requirements is provided for our clients and solutions. Our solution
                      offerings and managed services are designed to drive operational efficiencies and enhance productivity.
                    </p>
                  </div>
                  {/* <Link to='/single-service' className='link-with-icon text-decoration-none mt-3'>
                    View Details <i className='far fa-arrow-right'></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
